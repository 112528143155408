@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 2px;
  }

  .scrollbar::-webkit-scrollbar-track {
    /* border-radius: 100vh; */
    background: rgb(229, 229, 229);
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: rgb(205, 205, 205);
    /* border-radius: 100vh; */
    /* border: 3px solid #f6f7ed; */
  }
}
